<template>
  <base-section
    id="WaveSpecialties"
    color="white"
  >
    <div>
      <v-row
        v-for="i in (Specialties.length/2)"
        :key="i"
        align="center"
        justify="space-around"
      >
        <v-col
          v-for="j in 2"
          :key="getItemsNb(i, j)"
          cols="12"
          sm="6"
        >
          <miniweb-card-specialty
            :mdi="Specialties[getItemsNb(i, j)].icon"
            :title="Specialties[getItemsNb(i, j)].title"
            :sub-title="Specialties[getItemsNb(i, j)].subTitle"
            :position="Specialties[getItemsNb(i, j)].position"
          />
        </v-col>
      </v-row>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionWaveSpecialties',

    data: () => ({
      Specialties: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Plombier',
          subTitle: 'Super Strong',
          position: 'text-center ma-10',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Electricien',
          subTitle: 'Super Fast',
          position: 'text-center mt-n10 ml-10 mr-10',
        },
        {
          icon: 'mdi-email',
          title: 'Platrier',
          subTitle: 'Super Precise',
          position: 'text-center ma-10',
        },
        {
          icon: 'mdi-check-bold',
          title: 'Plaquite',
          subTitle: 'Super clean',
          position: 'text-center mt-n10 ml-10 mr-10',
        },
      ],
    }),
    computed: {
    },
    methods: {
      getItemsNb (row, column) {
        switch (row) {
          case 1:
            switch (column) {
              case 1: return 0
              case 2: return 1
            }
            break
          case 2:
            switch (column) {
              case 1: return 2
              case 2: return 3
            }
            break
        }
        return 0
      },
    },
  }
</script>
